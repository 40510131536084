import React, { useState, useEffect } from "react";
import "./hero.css";

function Hero() {
  const sentences = [
    "Passionate Mechanical Engineering graduate skilled in programming and CAD/CAM tools.",
    "Experienced in biomechanics research, additive manufacturing, and sustainable engineering.",
    "Strong collaborator with a talent for innovation.",
    "Eager to apply advanced technical skills and hands-on experience to create impactful solutions.",
  ];

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade-out
      setTimeout(() => {
        setCurrentSentenceIndex(
          (prevIndex) => (prevIndex + 1) % sentences.length
        );
        setFade(true); // Start fade-in
      }, 500); // Duration of fade-out
    }, 3500); // Change sentence every 3.5 seconds (including fade-out duration)

    return () => clearInterval(interval);
  }, [sentences.length]);

  return (
    <div className="hero">
      {/* Text for hero */}
      <div className="hero-text">
        <h1>Danial Manshadi</h1>
        <div className="hero-description">
          <p>
            <strong>Graduate Mechanical Engineer</strong>
          </p>
          <div className="sentence-container">
            <p className={fade ? "fade-in" : "fade-out"}>
              {sentences[currentSentenceIndex]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
